import type { DataObject, ItemModel, DataObjectOptions } from 'o365-dataobject';
import type { alert, confirm } from 'o365-vue-services';

import { LayoutType, getOrCreateDataObject, SharingCapabilitiesChecker } from 'o365-dataobject';
// import { SharingCapabilitiesChecker, procSetLayoutAsHidden, procUnsetLayoutAsHidden } from 'o365-dataobject';
import { app, userSession, context, configurableRegister } from 'o365-modules';
import { useDataObjectEventListener } from 'o365-vue-utils';
import { $t, logger } from 'o365-utils'
import { computed, ref } from 'vue';

const canShare = ref(false);
const canSetDefaults = ref(false);

export function useLayoutHelpers<T extends ItemModel>(pDataObject: DataObject<T>) {
    const dsLayouts = getLayoutsDataObject(pDataObject);
    const savingAs = ref(false);
    const isCreatingNewLayout = ref(false);

    const activeLayout = computed(() => pDataObject.layoutManager?.hasActiveLayout ? pDataObject.layoutManager.activeLayout : null);
    const activeLayoutID = computed(() => activeLayout.value?.id);
    const isDirty = computed(() => {
        const layoutManager = pDataObject && pDataObject.layoutManager;
        if (layoutManager == null || layoutManager.isSaving || !layoutManager.canSave) {
            return false;
        }
        const activeLayout = layoutManager.hasActiveLayout
            ? layoutManager.activeLayout
            : null;
        return activeLayout && activeLayout.hasChanges();
    });
    const isSaving = computed(() => {
        return activeLayout.value?.isSaving || savingAs.value || isCreatingNewLayout.value;
    });
    const canBeSavedAs = computed(() => {
        return (pDataObject.layoutManager?.canSaveAs ?? false) && activeLayout.value && (Object.keys(activeLayout.value.layout).length || (!activeLayout.value.id && isDirty.value));
    });
    const couldSaveCurrentLayout = computed(() => {
        if (activeLayout.value) {
            if (LayoutType.SharedFromPerson == activeLayout.value.layoutType) {
                return false;
            } else if ([LayoutType.SharedDefault, LayoutType.Shared, LayoutType.SharedPersonalDefault].includes(activeLayout.value.layoutType)) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    });

    async function setLayout(pId: number) {
        const options = dsLayouts.recordSource.getOptions();
        options.maxRecords = 1;
        options.whereClause = `[ID] = ${pId}`;
        options.fields!.push({ name: 'Layout' });
        const data = await dsLayouts.dataHandler.retrieve(options);
        const layout = data[0];
        pDataObject.layoutManager?.applyLayout(layout, true);
    };

    function save(pSkipCheck = false) {
        if (!pSkipCheck && activeLayout.value && (!activeLayout.value.id || activeLayout.value.isDefault)) {
            saveAsNew();
            return;
        }
        pDataObject.layoutManager?.saveLayout({
            includedModules: pDataObject.layoutManager.registeredModules
        });
    }

    async function createNewLayout() {
        isCreatingNewLayout.value = true;
        try {
            const result = await asyncConfirm({
                message: $t('Name'),
                title: $t('Create new layout'),
                btnTextOk: $t('Save'),
                btnTextCancel: $t('Cancel'),
                textInputRequired: true
            });
            const name = result.TextInput;
            if (!name) {
                throw new Error($t("Layout name can't be empty"));
            }
            await pDataObject.layoutManager?.createNewLayout({
                name: name
            });
            dsLayouts.load();
        } catch (ex) {
            logger.error(ex);
            if (ex && !ex.Canceled) {
                asyncAlert(ex.message);
            }
        } finally {
            isCreatingNewLayout.value = false;
        }
    }

    async function saveAsNew() {
        savingAs.value = true;
        try {
            const result = await asyncConfirm({
                message: $t('Name'),
                title: $t('Save Layout'),
                btnTextOk: $t('Save'),
                btnTextCancel: $t('Cancel'),
                textInputRequired: true
            });
            const name = result.TextInput;
            if (!name) {
                throw new Error($t("Layout name can't be empty"));
            }
            await pDataObject.layoutManager?.saveLayout({
                saveAsNew: true,
                newWithChanges: true,
                name: name
            });
            dsLayouts.load();
        } catch (ex: any) {
            if (!ex.Canceled) {
                asyncAlert(ex.message);
            }
        } finally {
            savingAs.value = false;
        }
    }

    async function setLayoutAsDefault(pLayout: any) {
        if (!pLayout?.ID) { return; }
        await pDataObject.layoutManager?.setLayoutAsDefault(pLayout.ID).then(() => {
            asyncAlert($t('Layout set as default'), 'success', { autohide: true });
            dsLayouts.load();
        });
    }

    try {
        SharingCapabilitiesChecker.getInstance().canSetDefaultLayouts().then(value => {
            canSetDefaults.value = value;
        });
        SharingCapabilitiesChecker.getInstance().canShare().then(value => {
            canShare.value = value;
         });
    } catch (ex) {
        logger.error(ex);
    }

    async function canShareInPath(pLayout: any) {
        if (pLayout.AccessIdPath) {
            return await SharingCapabilitiesChecker.getInstance().canShareInPath(pLayout.AccessIdPath);
        } else {
            return true;
        }
    }

    const canShareMap = ref<Record<number, boolean>>({});
    let layoutSharingDeboucne: number | null = null;
    async function checkForSHaredLayoutsCapabilities() {
        canShareMap.value = {};
        if (layoutSharingDeboucne) { window.clearTimeout(layoutSharingDeboucne); }
        layoutSharingDeboucne = window.setTimeout(() => {
            dsLayouts.data.filter(x => x.OrgUnit_ID).forEach(layout => {
                canShareInPath(layout).then(result => {
                    canShareMap.value[layout.ID] = result;
                });
            });
        }, 200);
    }

    useDataObjectEventListener(dsLayouts, 'DataLoaded', () => {
        checkForSHaredLayoutsCapabilities();
    });


    return { activeLayout, activeLayoutID, isDirty, couldSaveCurrentLayout, isSaving, setLayout, save, saveAsNew, canShare, canSetDefaults, setLayoutAsDefault, 
        canBeSavedAs, createNewLayout, isCreatingNewLayout, canShareMap };
}

export function getLayoutsDataObject<T extends ItemModel>(pDataObject: DataObject<T>) {
    const getFields = () => {
        const fields = ['ID', 'Name', 'Description', 'DataObject_ID', 'App_ID', 'Person_ID', 'OrgUnit_ID', 'Updated', 'AccessIdPath', 'OrgUnit', 'Hidden']
            .map(field => ({
                name: field,
            })) as DataObjectOptions['fields'];
        fields.push({ name: 'Default', sortOrder: 1, sortDirection: 'desc' });
        return fields;
    };
    const registerClause = configurableRegister.isConfigured ? `[Register_ID] = ${configurableRegister.id}` : '[Register_ID] IS NULL';

    const whereClauses = [
        `[DataObject_ID] = '${pDataObject.id}'`,
        `[App_ID] = '${app.id}'`,
        `[Hidden] = 0`,
        registerClause,
    ];
    return getOrCreateDataObject({
        id: `o_${pDataObject.id}_layoutsActionnsList`,
        viewName: 'sviw_O365_MyLayouts2',
        uniqueTable: 'stbv_O365_Layouts',
        fields: getFields(),
        disableLayouts: true,
        allowDelete: true,
        allowUpdate: true,
        whereClause: whereClauses.join(' AND ')
    });
}

function asyncAlert(...args: Parameters<typeof alert>) {
    return import('o365-vue-services').then((services) => services.alert(...args));
}

function asyncConfirm(...args: Parameters<typeof confirm>) {
    return import('o365-vue-services').then((services) => services.confirm(...args));
}